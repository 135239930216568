import { useCallback, useEffect, useState } from "react"

export function useTimer({
  seconds,
  onTimesUp,
}: {
  seconds: number
  onTimesUp?: () => void
}) {
  const [secondsLeft, setSecondsLeft] = useState(seconds)
  const [isActive, setIsActive] = useState(false)

  const resetTimer = useCallback(() => {
    setSecondsLeft(seconds)
    setIsActive(false)
  }, [seconds])

  const startTimer = useCallback(() => {
    resetTimer()
    setIsActive(true)
  }, [resetTimer])

  const tick = () => setSecondsLeft(s => s - 1)

  useEffect(() => {
    let ticker = 0
    if (isActive) {
      ticker = window.setInterval(tick, 1000)
    }
    // Un-mounting or updating state of `isActive`causes interval to clear
    return () => clearInterval(ticker)
  }, [isActive])

  useEffect(() => {
    // IMPORTANT: want to ensure `onTimesUp` only triggers once
    // Right now, this is ensured because `isActive`, but in the future it could be a special `isTimeUpTriggered` state
    if (secondsLeft === 0 && isActive) {
      onTimesUp && onTimesUp()
      // This causes the ticker useEffect hook to re-run and therefore clear because of the return value being what it is
      setIsActive(false)
    }
  }, [onTimesUp, secondsLeft, isActive])

  const isTimeUp = secondsLeft === 0
  const isTimerStarted = isActive

  return {
    startTimer,
    resetTimer,
    isTimerStarted,
    secondsLeft,
    isTimeUp,
  }
}
