import {
  CircularProgress,
  CircularProgressLabel,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react"
import { css } from "@emotion/react"
import React from "react"

export function Timer({
  isStarted = true,
  seconds,
  secondsLeft,
  isRed = false,
}: {
  isStarted?: boolean
  seconds: number
  secondsLeft: number
  isRed?: boolean
}) {
  const trackColor = useColorModeValue("gray.100", "gray.700")
  const color = useColorModeValue("gray.900", "gray.200")
  const redColor = useColorModeValue("red.500", "red.200")
  return (
    <Flex alignItems="center">
      <CircularProgress
        trackColor={trackColor}
        color={isRed ? redColor : color}
        value={isStarted ? Math.round((secondsLeft / seconds) * 100) : 0}
        thickness=".2em"
        size="1.5em"
        mr={1}
        css={css`
          svg > circle {
            transition-duration: 0.1, 0.1 !important;
          }
        `}
      >
        <CircularProgressLabel fontSize="1rem">
          {secondsLeft}
        </CircularProgressLabel>
      </CircularProgress>
      Seconds
    </Flex>
  )
}
