import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  useToast,
  VStack,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { DebugLayout } from "../../components/debug/DebugLayout"
import { Json } from "../../components/Json"
import { Pulse } from "../../components/Pulse"
import { Timer } from "../../components/typing-test/Timer"
import { useTimer } from "../../useTimer"

function TimerPageInner() {
  const [duration, setDuration] = useState(30)
  const toast = useToast()
  const {
    startTimer,
    resetTimer,
    secondsLeft,
    isTimeUp,
    isTimerStarted,
  } = useTimer({
    seconds: duration,
    onTimesUp: () =>
      toast({ title: "Time's up!", isClosable: true, duration: 3000 }),
  })

  return (
    <VStack spacing={6} align="start" p={6}>
      <FormControl>
        <FormLabel>Duration</FormLabel>
        <Input
          type="number"
          value={duration}
          onChange={e => setDuration(parseInt(e.target.value))}
        />
      </FormControl>
      <HStack spacing={3} align="flex-end">
        <Pulse when={isTimerStarted && secondsLeft === duration}>
          {secondsLeft <= 5 ? (
            <Timer
              isStarted={isTimerStarted}
              seconds={5}
              secondsLeft={secondsLeft}
              isRed
            />
          ) : (
            <Timer
              isStarted={isTimerStarted}
              seconds={duration}
              secondsLeft={secondsLeft}
            />
          )}
        </Pulse>
        <Button onClick={startTimer}>Start</Button>
        <Button onClick={resetTimer}>Reset</Button>
      </HStack>
      <Json>{{ isTimeUp, isTimerStarted }}</Json>
      <Divider />
      <Timer seconds={30} secondsLeft={30} />
      <Timer seconds={30} secondsLeft={15} />
      <Timer seconds={30} secondsLeft={5} isRed />
      <Timer seconds={30} secondsLeft={2} isRed />
      <Timer seconds={30} secondsLeft={0} isRed />
      <Timer seconds={30} secondsLeft={-10} isRed />
    </VStack>
  )
}

export default function TimerPage() {
  return (
    <DebugLayout title="Timer">
      <TimerPageInner />
    </DebugLayout>
  )
}
